import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import {Form} from 'react-bootstrap'

import {updateAccountUser} from '../services'
import { handleError } from '../../../helpers'

export default function EditUser({ accountId, user, reloadUsers, show, setShow }) {   
   const [loading, setLoading] = useState(false)
   const {handleSubmit, errors, register} = useForm()

   const onSubmit = values => {
      setLoading(true)
      updateAccountUser(accountId, user.id, values)
         .then(() => {
            reloadUsers()
            setShow(false)
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   if(!user) return null
   
   let role = user.roles[0].name;

   return (
      <React.Fragment>
         <Modal isOpen={show} toggle={()=>setShow(!show)} centered={true}>
            <ModalHeader toggle={()=>setShow(false)}>Editar Usuario</ModalHeader>
            <ModalBody>
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                     <Form.Label>Nombre Completo</Form.Label>
                     <Form.Control 
                        name="name"
                        placeholder="Escriba el nombre del usuario nuevo"
                        ref={register({required:true})}
                        defaultValue={user.name}
                     />
                     { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Correo Electrónico</Form.Label>
                     <Form.Control 
                        name="email"
                        type="email"
                        placeholder="Escriba un correo válido para el usuario nuevo"
                        ref={register({required:true})}
                        defaultValue={user.email}
                     />
                     { errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Form.Group>
                     <Form.Label className="block">Rol del Usuario</Form.Label>
                     <Form.Check 
                        inline 
                        label="Super Admin"
                        value="Super Admin" 
                        type="radio"
                        name="role"
                        ref={register({required:true})} 
                        defaultChecked={role === 'Super Admin' ? true : false}
                     />
                     <Form.Check 
                        inline 
                        label="Admin" 
                        value="Admin" 
                        type="radio"
                        name="role"
                        ref={register({required:true})} 
                        defaultChecked={role === 'Admin' ? true : false}
                     />
                     <Form.Check 
                        inline 
                        label="Asesor" 
                        value="Agent" 
                        type="radio"
                        name="role"
                        ref={register({required:true})}
                        defaultChecked={role === 'Agent' ? true : false}
                     />
                     { errors.role && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Button 
                     color="primary" 
                     type="submit"
                     disabled={loading}
                  >
                     {!loading ? 'Editar Usuario' : 'Cargando...' }
                  </Button>
               </Form>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}