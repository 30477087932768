import React, { Component } from "react";
import { Container } from "reactstrap";

import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false
    };    
  }

  componentDidMount() {

    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";

      setTimeout(function () {

        document.getElementById('preloader').style.display = "none";
        document.getElementById('status').style.display = "none";
      }, 2500);
    }
    else {
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }
    
    window.scrollTo(0, 0);
    
    document.title = "Smart4 Sales Admin";
    
    document.body.setAttribute('data-topbar', 'dark'); 
    document.body.setAttribute('data-layout', 'horizontal');
    // document.body.setAttribute('data-layout-size', 'boxed');
    document.body.removeAttribute('data-sidebar');    
    
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = e => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };
  render() {
    return (
      <React.Fragment>

        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header theme={this.props.topbarTheme}
            isMenuOpened={this.state.isMenuOpened}            
            openLeftMenuCallBack={this.openMenu} />
          <Navbar menuOpen={this.state.isMenuOpened} />
          <div className="main-content">
            <div className="page-content">
              <Container fluid>
                {this.props.children}
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Layout;
