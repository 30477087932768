import s4salesapi from '../../services/s4salesapi'

export const getAccounts = () => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/accounts')
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getAccount = id => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get('/accounts/'+id)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createAccount = data => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post('/accounts', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateAccount = (id, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/accounts/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

// Account Users

export const getAccountUsers = accountId => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/accounts/${accountId}/users`)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

export const createAccountUser = (accountId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/accounts/${accountId}/users`, data)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

export const updateAccountUser = (accountId, userId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .put(`/accounts/${accountId}/users/${userId}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

export const deleteAccountUser = (accountId, userId) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/accounts/${accountId}/users/${userId}`)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

// Account Domains

export const getAccountDomains = accountId => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .get(`/accounts/${accountId}/domains`)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

export const attachAccountDomain = (accountId, data) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .post(`/accounts/${accountId}/domains`, data)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)

export const detachAccountDomain = (accountId, domain) => (
   new Promise((resolve, reject) => {
      s4salesapi()
         .delete(`/accounts/${accountId}/domains/${domain}`)
            .then(response => resolve(response))
            .catch(error => reject(error)) 
   })
)