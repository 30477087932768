import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Table, UncontrolledTooltip, Spinner, Button } from "reactstrap";
import { Empty } from 'antd';

import {getAccounts} from './services'
import {handleError} from '../../helpers'
import Breadcrumbs from '../../components/Breadcrumb';

export default function AccountsList() {
   const [accounts, setAccounts] = useState(null)

   !accounts && getAccounts()
      .then(response => {
         setAccounts(response.data)
      })
      .catch(error => handleError(error))

   if(accounts && accounts.length === 0) 
      return <Empty description="No se encontraron cuentas" />

   return (
      <React.Fragment>
         <Breadcrumbs title="Administrador" breadcrumbItem="Manejo de Cuentas" />
         <Row>
            <Col lg="12">            
               <div className="table-responsive">
                  <Table className="project-list-table table-nowrap table-centered table-borderless" style={{marginBottom: '150px'}}>
                     <thead>
                        <tr>
                           <th scope="col" style={{ width: "50px" }}></th>
                           <th scope="col">Cuenta / Dominios</th>                              
                           <th scope="col" style={{ width: "150px" }}>Status</th>
                           <th scope="col" style={{ width: "200px" }}>Límites</th>
                           <th scope="col" style={{ width: "150px" }}>Acciones</th>
                        </tr>
                     </thead>
                     <tbody>
                        { accounts ? (
                           <React.Fragment>
                              { accounts.map((account,index) => (
                                 <tr key={ account.data.id }>
                                    <td>
                                       <div className="rounded-circle header-profile-user d-xl-inline-block">
                                          <span className="avatar-title rounded-circle bg-soft-primary">
                                             <i className="mdi mdi-domain text-primary"></i>
                                          </span>
                                       </div>
                                    </td>
                                    <td>
                                       <h5 className="text-truncate font-size-15 mb-0">
                                          <Link to={`/accounts/${account.data.id}`} className="text-dark">{account.data.account_name}</Link>
                                       </h5>
                                       <p className="text-muted mb-0 font-size-12">
                                          { account.domains.join(', ') }
                                       </p>
                                    </td>
                                    <td>
                                       { account.data.is_suspended === "1" ? (
                                          <span className="badge badge-danger">Suspendida</span>
                                       ) : (
                                          <span className="badge badge-primary">Activa</span>
                                       )}
                                    </td>
                                    <td>
                                       <div className="team">
                                          <div className="team-member d-inline-block mr-2" id={`member1-${index}`}>
                                             <span className="avatar-title bg-soft-info p-1">
                                                <i className="mdi mdi-account-multiple text-info"></i>                                                
                                             </span>
                                             <UncontrolledTooltip placement="top" target={`member1-${index}`}>
                                                Contactos: { account.data.contacts_limit }
                                             </UncontrolledTooltip>
                                          </div>
         
                                          <div className="team-member d-inline-block mr-2" id={`member2-${index}`}>
                                             <span className="avatar-title bg-soft-info p-1">
                                                <i className="mdi mdi-clipboard-account text-info"></i>
                                             </span>
                                             <UncontrolledTooltip placement="top" target={`member2-${index}`}>
                                                Usuarios: { account.data.users_limit }
                                             </UncontrolledTooltip>
                                          </div>
         
                                          <div className="team-member d-inline-block mr-2" id={`member3-${index}`}>
                                             <span className="avatar-title bg-soft-info p-1">
                                                <i className="mdi mdi-message-processing text-info"></i>
                                             </span>
                                             <UncontrolledTooltip placement="top" target={`member3-${index}`}>
                                                Sms Credits: { account.data.sms_credits }
                                             </UncontrolledTooltip>
                                          </div>
         
                                          <div className="team-member d-inline-block" id={`member4-${index}`}>
                                             <span className="avatar-title bg-soft-info p-1">
                                                <i className="mdi mdi-help-circle-outline text-info"></i>
                                             </span>
                                             <UncontrolledTooltip placement="top" target={`member4-${index}`}>
                                                Soporte: { account.data.support_level }
                                             </UncontrolledTooltip>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                       <Link to={`/accounts/${account.data.id}`}>
                                          <Button color="dark" outline size="sm">Ver Cuenta</Button>
                                       </Link>
                                    </td>
                                 </tr>
                              ))}
                           </React.Fragment>
                        ) : (
                           <tr className="text-center">
                              <td colSpan="5">
                                 <Spinner />
                              </td>
                           </tr>
                        )}                  
                     </tbody>
                  </Table>
               </div>            
            </Col>
         </Row>      
      </React.Fragment>
   )
}