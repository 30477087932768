import React from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

import Login from './Login'
import Logout from './Logout'
import Forgot from './Forgot'

export default function Authentication() {
   const token = useSelector(state => state.auth.token)
      
   return (
      <Switch>
         <Route exact path="/auth/logout" component={Logout} />

         {token && <Redirect to="/" />}

         <Route exact path="/auth/login" component={Login} />
         <Route exact path="/auth/forgot" component={Forgot} />

         <Redirect to="/404" />
      </Switch>
   )
}