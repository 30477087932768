import React from 'react'
import {Card, CardTitle, CardBody, Table, Spinner} from 'reactstrap'
import EditAccount from './partials/EditAccount'

export default function AccountDetails({ account, reloadAccount }) {   
   return (
      <Card>
         <CardBody>
            <CardTitle>Detalles de Cuenta</CardTitle>
            <p className="text-muted mb-4">Datos y limites asignados a la cuenta</p>
            { account ? (
               <React.Fragment>
                  <Table className="table-nowrap mb-0" responsive>
                     <tbody>
                        <tr>
                           <th scope="row">Cuenta:</th>
                           <td>{ account.data.account_name }</td>
                        </tr>
                        <tr>
                           <th scope="row">Límite de Usuarios:</th>
                           <td>{ account.data.users_limit }</td>
                        </tr>
                        <tr>
                           <th scope="row">Límite de Contactos:</th>
                           <td>{ account.data.contacts_limit }</td>
                        </tr>
                        <tr>
                           <th scope="row">Créditos SMS:</th>
                           <td>{ account.data.sms_credits }</td>
                        </tr>
                        <tr>
                           <th scope="row">Nivel de Soporte:</th>
                           <td>{ account.data.support_level.toUpperCase() }</td>
                        </tr>
                        <tr>
                           <th scope="row">ID Base de Datos:</th>
                           <td>...{ account.data.id.substr(account.data.id.length - 12) }</td>
                        </tr>
                     </tbody>
                  </Table> 
                  <div className="text-center mt-3">
                     <EditAccount account={account} reloadAccount={()=>reloadAccount()} />
                  </div>
               </React.Fragment>
            ) : (
               <Spinner size="sm" />
            )}
         </CardBody>
      </Card>
   )
}