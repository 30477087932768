import React from 'react';
import { Card, CardBody, Media } from "reactstrap";

export default function StatCard({ title, text, iconClass }) {
   return (
      <Card className="mini-stats-wid">
         <CardBody>
            <Media>
               <Media body>
                  <p className="text-muted font-weight-medium">{title}</p>
                  <h4 className="mb-0">{text}</h4>
               </Media>

               <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                  <span className="avatar-title">
                     <i className={"bx " + iconClass + " font-size-24"}></i>
                  </span>
               </div>
            </Media>
         </CardBody>
      </Card>
   )
}