import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import {Form} from 'react-bootstrap'

import {createAccountUser} from '../services'
import { handleError } from '../../../helpers'

export default function NewUser({ accountId, reloadView }) {
   const [show, setShow] = useState(false)
   const [loading, setLoading] = useState(false)
   const {handleSubmit, errors, register, watch} = useForm()

   const onSubmit = values => {
      setLoading(true)
      createAccountUser(accountId, values)
         .then(() => {
            reloadView()
            setShow(false)
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <React.Fragment>
         <Button outline color="primary" size="sm" onClick={()=>setShow(true)}>Agregar Usuario</Button>
         <Modal isOpen={show} toggle={()=>setShow(!show)} centered={true}>
            <ModalHeader toggle={()=>setShow(false)}>Agregar Nuevo Usuario</ModalHeader>
            <ModalBody>
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                     <Form.Label>Nombre Completo</Form.Label>
                     <Form.Control 
                        name="name"
                        placeholder="Escriba el nombre del usuario nuevo"
                        ref={register({required:true})}
                     />
                     { errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Correo Electrónico</Form.Label>
                     <Form.Control 
                        name="email"
                        type="email"
                        placeholder="Escriba un correo válido para el usuario nuevo"
                        ref={register({required:true})}
                     />
                     { errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Contraseña</Form.Label>
                     <Form.Control 
                        name="password"
                        type="password"
                        placeholder="Escriba la contraseña de acceso del usuario"
                        autoComplete="new-password"
                        ref={register({required:true})}
                     />
                     { errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Confirmar Contraseña</Form.Label>
                     <Form.Control 
                        name="password_confirmation"
                        type="password"
                        placeholder="Confirme la contraseña de acceso"
                        ref={register({
                           required:true,
                           validate: value => value === watch('password')
                        })}
                     />
                     { errors.password_confirmation && <Form.Text className="text-warning">La contraseña no coincide.</Form.Text> }
                  </Form.Group>
                  <Form.Group>
                     <Form.Label className="block">Rol del Usuario</Form.Label>
                     <Form.Check inline label="Super Admin" value="Super Admin" type="radio" name="role" ref={register({required:true})} />
                     <Form.Check inline label="Admin" value="Admin" type="radio" name="role" ref={register({required:true})} />
                     <Form.Check inline label="Asesor" value="Agent" type="radio" name="role" ref={register({required:true})} />
                     { errors.role && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Button 
                     color="primary" 
                     type="submit"
                     disabled={loading}
                  >
                     {!loading ? 'Agregar Usuario' : 'Cargando...' }
                  </Button>
               </Form>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}