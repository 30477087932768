import s4salesapi from '../../services/s4salesapi'

export const getFilters = () => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.get('/public/filters')
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getFilter = id => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.get(`/filters/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createFilter = data => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.post('/filters', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateFilter = (id, data) => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.put(`/filters/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteFilter = id => (
	new Promise((resolve, reject) => {
		s4salesapi()
			.delete(`/filters/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)