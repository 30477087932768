import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import MainLayout from '../../layouts/MainLayout'
import AccountsList from './AccountsList';
import AccountProfile from './AccountProfile';
import NewAccount from './NewAccount'

export default function Accounts() {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/accounts" component={AccountsList} />
            <Route exact path="/accounts/new" component={NewAccount} />
            <Route exact path="/accounts/:id" component={AccountProfile} />

            <Redirect to="/404" />
         </Switch>
      </MainLayout>
   )
}