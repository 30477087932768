import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items.pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active");
            const parent2 = parent.parentElement;
            parent2.classList.add("active");            
        }
        return false;
    };

    render() {
        return (
            <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                        <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/accounts" className="nav-link">
                                        <i className="bx bx-customize mr-2"></i>
                                        Listado de Cuentas
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/accounts/new" className="nav-link">
                                        <i className="bx bx-plus mr-2"></i>
                                        Crear Nueva Cuenta
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/filters" className="nav-link">
                                        <i className="bx bx-filter-alt mr-2"></i>
                                        Filtros Permitidos
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        );
    }
}

export default withRouter(Navbar);
