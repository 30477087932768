import React, {useState} from 'react'
import {Card, CardTitle, CardBody, Spinner, Button} from 'reactstrap'
import {Table, Popconfirm, Divider} from 'antd'

import {getAccountUsers, deleteAccountUser} from './services'
import {handleError} from '../../helpers'
import NewUser from './partials/NewUser'
import EditUser from './partials/EditUser'

export default function AccountUsers({ account, reloadAccount }) {
   const [users, setUsers] = useState(null)
   const [selectedUser, setSelectedUser] = useState(null)
   const [showEditModal, setShowEditModal] = useState(false)

   account && !users &&
      getAccountUsers(account.data.id)
         .then(response => setUsers(response.data))
         .catch(error => handleError(error))

   const handleDelete = id => {
      deleteAccountUser(account.data.id, id)
         .then(() => { setUsers(null); reloadAccount(); })
         .catch(error => handleError(error))
   }

   const handleEdit = user => {
      setSelectedUser(user)
      setShowEditModal(true)
   }

   const columns = [
      {
         title: "Nombre",
         dataIndex: "name",
         sorter: true,
      },
      {
         title: "Correo Electrónico",
         sorter: true,
         dataIndex: "email",
      },
      {
         title: "Verificación",
         sorter: true,
         dataIndex: "active",
         render: status => (
            status === 1 ? (
               <span className="badge badge-soft-primary">Verificado</span>
            ) : (
               <span className="badge badge-soft-danger">Pendiente</span>
            )
         )
      },
      {
         title: "Acceso",
         dataIndex: "roles",
         render: roles => (
            roles.map(role => <span className="badge badge-soft-dark" key={role.id}>{role.name}</span>)
         )
      },
      {
         title: "Acciones",      
         render: (text, record) => (
            <React.Fragment>
               <Button color="info" outline size="sm" onClick={()=>handleEdit(record)}>
                  <i className="dripicons-pencil"></i>
               </Button>
               <Divider type="vertical" />
               <Popconfirm
                  title="¿Esta seguro de eliminar el usuario?"
                  onConfirm={()=> handleDelete(record.id)}
                  okText="Eliminar"
                  cancelText="Cancelar"
               >
                  <Button color="danger" outline size="sm">
                     <i className="dripicons-trash"></i>
                  </Button>
               </Popconfirm>
            </React.Fragment>
         )
      }
   ]

   return (
      <React.Fragment>
         <Card>
            <CardBody>
               <CardTitle>
                  <div className="float-right">
                     <NewUser accountId={account && account.data.id} reloadView={()=>{reloadAccount(); setUsers(null);}} />
                  </div>
                  Usuarios de la Cuenta
               </CardTitle>
               <p className="text-muted mb-4">Administración de los usuarios asociados en la cuenta</p>
               { users ? (
                  <React.Fragment>                  
                     <Table 
                        dataSource={users}
                        columns={columns}
                        showSorterTooltip={false}
                        rowKey={record => record.id}
                        />                  
                  </React.Fragment>
               ) : (
                  <Spinner size="sm" />
                  )}
            </CardBody>         
         </Card>
         <EditUser 
            accountId={account && account.data.id} 
            user={selectedUser}
            reloadUsers={()=>setUsers(null)} 
            show={showEditModal} 
            setShow={()=>setShowEditModal()} 
         />
      </React.Fragment>
   )
}