import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap'

import { updateFilter } from '../services'
import { handleError } from '../../../helpers'
import ModalHeader from 'react-bootstrap/ModalHeader'

export default function EditFilterModal({ visible, filter, onClose }) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		updateFilter(filter.id, values)
			.then(() => onClose(true))
			.catch(error => handleError(error))
	}

	if(!filter) return null

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Editar Filtro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col} lg="6">
								<Form.Label>Group</Form.Label>
								<Form.Control as="select" disabled>
									<option value="contacts">Contactos</option>
								</Form.Control>
							</Form.Group>
							<Form.Group as={Col} lg="6">
								<Form.Label>Type</Form.Label>
								<Form.Control as="select" defaultValue={filter.type} name="type" ref={register({})}>
									<option value="text">Tipo Texto</option>
									<option value="number">Tipo Númerico</option>
									<option value="date">Tipo Fecha</option>
									<option value="boolean">Tipo Boolean</option>
								</Form.Control>
							</Form.Group>
							<Form.Group as={Col} lg="6">
								<Form.Label>Key</Form.Label>
								<Form.Control defaultValue={filter.key} disabled />
								{ errors.key && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col} lg="6">
								<Form.Label>Name</Form.Label>
								<Form.Control defaultValue={filter.name} disabled />
								{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
						</Row>				
						<Form.Group>
							<Form.Label>Label</Form.Label>
							<Form.Control defaultValue={filter.label} name="label" ref={register({ required: true })} />
							{ errors.label && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Operator</Form.Label>
							<Form.Control defaultValue={filter.operator} name="operator" ref={register({ required: true })} />
							{ errors.operator && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Hint</Form.Label>
							<Form.Control defaultValue={filter.hint} name="hint" ref={register({ required: true })} />
							{ errors.hint && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<div className="mt-3 text-right">
							<Button color="primary">Actualizar Filtro</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}