import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Row, Col, Card, CardBody, Button} from 'reactstrap';
import { Form, InputGroup } from 'react-bootstrap'

import Breadcrumbs from '../../components/Breadcrumb';
import {createAccount} from './services'
import { handleError } from '../../helpers';
import { message } from 'antd';

export default function NewAccount() {
   const {handleSubmit, register, errors, reset} = useForm()
   const [loading, setLoading] = useState(false)

   const onSubmit = values => {      
      setLoading(true)
      values.sms_credits = "0"
      values.manager_id = "1"
      values.is_suspended = "0"
      values.domain += ".smart4sales.com"
      createAccount(values)
         .then(() => {
            message.success('Cuenta creada exitosamente.')
            reset()
         })
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <React.Fragment>
         <Breadcrumbs title="Administrador" breadcrumbItem="Creación de Nuevas Cuentas" />
         <Row>
            <Col md="8" lg="6">
               <Card>   
                  <CardBody>
                     <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                           <Form.Label>Nombre de la Cuenta</Form.Label>
                           <Form.Control
                              name="account_name"
                              ref={register({required:true})}
                           />
                           { errors.account_name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                        <Form.Group>
                           <Form.Label>ID de la Cuenta</Form.Label>
                           <Form.Control
                              name="id"
                              ref={register({required:true})}
                           />
                           { errors.id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                        <Row>
                           <Col>
                              <Form.Group>
                                 <Form.Label>Límite de Usuarios</Form.Label>
                                 <Form.Control
                                    name="users_limit"
                                    type="number"
                                    ref={register({required:true, min: 1, max: 100})}
                                 />
                                 { errors.users_limit && <Form.Text className="text-warning">Este campo es requerido. Rango de 1 a 100</Form.Text> }
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group>
                                 <Form.Label>Límite de Contactos</Form.Label>
                                 <Form.Control
                                    name="contacts_limit"
                                    type="number"
                                    ref={register({required:true, min:10, max:1000000})}
                                 />
                                 { errors.contacts_limit && <Form.Text className="text-warning">Este campo es requerido. Rango de 10 a 1M</Form.Text> }
                              </Form.Group>
                           </Col>
                        </Row>
                        <Row>
                           <Col md="4">
                              <Form.Group>
                                 <Form.Label>Nivel de Soporte</Form.Label>
                                 <Form.Control
                                    as="select"
                                    name="support_level"
                                    ref={register({required:true})}
                                 >
                                    <option value="basic">Básico</option>
                                    <option value="plus">Plus</option>
                                    <option value="premium">Premium</option>
                                 </Form.Control>
                                 { errors.support_level && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group>
                                 <Form.Label>Dominio Principal</Form.Label>
                                 <InputGroup className="mb-3">
                                    <Form.Control
                                       name="domain"
                                       ref={register({required:true})}
                                    />
                                    <InputGroup.Append>
                                       <InputGroup.Text>.smart4sales.com</InputGroup.Text>
                                    </InputGroup.Append>
                                 </InputGroup>
                                 { errors.domain && <Form.Text className="text-warning">Ingrese el dominio principal.</Form.Text> }
                              </Form.Group>
                           </Col>
                        </Row>
                        <Button
                           color="primary"
                           type="submit"
                           disabled={loading}
                        >
                           {!loading ? 'Crear Cuenta' : 'Cargando...' }
                        </Button>
                     </Form>
                  </CardBody>               
               </Card>
            </Col>
         </Row>
      </React.Fragment>
   )
}