import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useForm} from "react-hook-form"
import {Form, Button} from 'react-bootstrap'
import {handleError} from '../../helpers'

import AuthLayout from "../../layouts/AuthLayout"

import {login} from './services'
import {userLogin} from './actions'

export default function Login() {    
    const dispatch = useDispatch()
    const { handleSubmit, register, errors } = useForm();
    
    const onSubmit = values => {
        login(values)
            .then(response => {
                dispatch(userLogin(response.data))
            })
            .catch(error => handleError(error))
    }

    return (
        <AuthLayout title="Inicio de Sesión" description="Administrador de Cuentas">
            <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Control 
                        name="email" 
                        placeholder="Escriba su correo electrónico" 
                        type="email"
                        ref={register({ required: true })}
                    />
                    {errors.email && <Form.Text className="text-warning">Este campo es requerido</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Control 
                        name="password" 
                        type="password" 
                        ref={register({ required: true })}
                        placeholder="Escriba su contraseña"                        
                    />
                    {errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
                </Form.Group>

                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                    <label className="custom-control-label" htmlFor="customControlInline">Recordarme</label>
                </div>

                <div className="mt-3">
                    <Button className="btn-block waves-effect waves-light" type="submit">Iniciar Sesión</Button>
                </div>

                <div className="mt-4 text-center">
                    <Link to="/auth/forgot" className="text-muted"><i className="mdi mdi-lock mr-1"></i> ¿Ólvido su contraseña?</Link>
                </div>
            </Form>
        </AuthLayout>
    )
}