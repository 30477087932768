import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MainLayout from '../../layouts/MainLayout'
import FiltersList from './FiltersList'

export default function Filters() {
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/filters" component={FiltersList} />
				
				<Redirect to="/404" />
			</Switch>
		</MainLayout>
	)
}