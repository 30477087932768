import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Button, Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap'
import {Form} from 'react-bootstrap'

import {updateAccount} from '../services'
import {handleError} from '../../../helpers'

export default function EditAccount({ account, reloadAccount }) {
   const [show, setShow] = useState(false)
   const [loading, setLoading] = useState(false)
   const {handleSubmit, errors, register, setError, clearError} = useForm()

   if(!account) return null

   const onSubmit = values => {
      if(parseInt(values.users_limit) < parseInt(account.stats.users_count)){ setError('users_limit'); return null; }
      if(parseInt(values.contacts_limit) <= parseInt(account.stats.contacts_count)){ setError('contacts_limit'); return null; }

      setLoading(true)
      updateAccount(account.data.id, values)
         .then(() => reloadAccount())
         .catch(error => handleError(error))
         .finally(() => setLoading(false))
   }

   return (
      <React.Fragment>
         <Button 
            color="primary" 
            outline 
            size="sm" 
            onClick={()=>setShow(true)}
         >
            Editar Cuenta
         </Button>
         <Modal isOpen={show} toggle={()=>setShow(!show)} centered={true}>
            <ModalHeader toggle={()=>setShow(false)}>Editar Cuenta</ModalHeader>
            <ModalBody>
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                     <Form.Label>Nombre de la Cuenta</Form.Label>
                     <Form.Control
                        name="account_name"
                        ref={register({required:true})}
                        defaultValue={account.data.account_name}
                     />
                     { errors.account_name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                  </Form.Group>
                  <Row>
                     <Col>
                        <Form.Group>
                           <Form.Label>Límite de Usuarios</Form.Label>
                           <Form.Control
                              name="users_limit"
                              type="number"
                              ref={register({required:true})}
                              defaultValue={account.data.users_limit}
                              onChange={e => {
                                 if (parseInt(e.target.value) < parseInt(account.stats.users_count)) setError("users_limit", "required")
                                 else clearError("users_limit")
                               }}
                           />
                           { errors.users_limit && <Form.Text className="text-warning">Ingrese un limite. No puede ser menor a consumo actual.</Form.Text> }
                        </Form.Group>
                     </Col>
                     <Col>
                        <Form.Group>
                           <Form.Label>Límite de Contactos</Form.Label>
                           <Form.Control
                              name="contacts_limit"
                              type="number"
                              ref={register({required:true})}
                              defaultValue={account.data.contacts_limit}
                              onChange={e => {                  
                                 if (parseInt(e.target.value) <= parseInt(account.stats.contacts_count)) setError("contacts_limit", "required")
                                 else clearError("contacts_limit")                                 
                               }}
                           />
                           { errors.contacts_limit && <Form.Text className="text-warning">Ingrese un limite. No puede ser menor a consumo actual.</Form.Text> }
                        </Form.Group>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <Form.Group>
                           <Form.Label>Nivel de Soporte</Form.Label>
                           <Form.Control
                              as="select"
                              name="support_level"
                              ref={register({required:true})}
                              defaultValue={account.data.support_level}
                           >
                              <option value="basic">Básico</option>
                              <option value="plus">Plus</option>
                              <option value="premium">Premium</option>
                           </Form.Control>
                           { errors.support_level && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                     </Col>
                     <Col>
                        <Form.Group>
                           <Form.Label>Estado Actual</Form.Label>
                           <Form.Control
                              as="select"
                              name="is_suspended"
                              ref={register({required:true})}
                              defaultValue={account.data.is_suspended}
                           >
                              <option value="0">Activa</option>
                              <option value="1">Suspendida</option>
                           </Form.Control>
                           { errors.is_suspended && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
                        </Form.Group>
                     </Col>
                  </Row>
                  <Button
                     color="primary"
                     type="submit"
                     disabled={loading}
                  >
                     {!loading ? 'Editar Cuenta' : 'Cargando...' }
                  </Button>
               </Form>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}