import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Row, Col, Button } from 'reactstrap'

import { createFilter } from '../services'
import { handleError } from '../../../helpers'

export default function NewFilterForm({ reload }) {
	const { handleSubmit, register, errors, reset } = useForm()

	const onSubmit = values => {
		createFilter(values)
			.then(() => {
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Form.Group as={Col} lg="6">
						<Form.Control as="select" ref={register({})} name="group">
							<option value="contacts">Contactos</option>
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col} lg="6">
						<Form.Control as="select" name="type" ref={register({})}>
							<option value="text">Tipo Texto</option>
							<option value="number">Tipo Númerico</option>
							<option value="date">Tipo Fecha</option>
							<option value="boolean">Tipo Boolean</option>
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col} lg="6">
						<Form.Control placeholder="Key" name="key" ref={register({ required: true })} />
						{ errors.key && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group as={Col} lg="6">
						<Form.Control placeholder="Filtro" name="name" ref={register({ required: true })} />
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
				</Row>				
				<Form.Group>
					<Form.Control placeholder="Nombre Legible" name="label" ref={register({ required: true })} />
					{ errors.label && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Control placeholder="Nombre de Operación" name="operator" ref={register({ required: true })} />
					{ errors.operator && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Control placeholder="Texto de Ayuda" name="hint" ref={register({ required: true })} />
					{ errors.hint && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<div className="mt-3 text-right">
					<Button color="primary">Crear Filtro</Button>
				</div>
			</Form>
		</React.Fragment>
	)
}