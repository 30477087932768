import React from 'react';

import { Row, Col, Card, CardBody, Container } from "reactstrap";

import profile from "../assets/images/profile-img.png";
import logo from "../assets/images/logo.png";

export default function AuthLayout(props) {
    return (
        <div className="account-pages my-5 pt-sm-5">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden">
                            <div className="bg-soft-primary">
                                <Row>
                                    <Col className="col-7">
                                        <div className="text-primary p-4">
                                            <h4 className="text-primary">{props.title}</h4>
                                            <p>{props.description}</p>
                                        </div>
                                    </Col>
                                    <Col className="col-5 align-self-end">
                                        <img src={profile} alt="" className="img-fluid" />
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <div>                    
                                    <div className="avatar-md profile-user-wid mb-4">
                                        <span className="avatar-title rounded-circle bg-light">
                                            <img src={logo} alt="" className="rounded-circle" height="34" />
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2">
                                    {props.children}
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mt-5 text-center">
                            <p>¿Problemas para ingresar? <a href="mailto:soporte@smart4.com.co" className="font-weight-medium text-primary"> Contacta al equipo de soporte </a></p>
                            <p>© {new Date().getFullYear()} Smart4 Solutions. Hecho con <i className="mdi mdi-heart-pulse text-danger"></i> <i className="mdi mdi-brain text-danger"></i><i className="mdi mdi-power-plug text-danger"></i> en Colombia</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}