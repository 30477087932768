import React, {useState} from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumb';
import NewFilterForm from './partials/NewFilterForm';
import FiltersTable from './partials/FiltersTable';
import { getFilters } from './services';
import {handleError} from '../../helpers'

export default function FiltersList() {
	const [filters, setFilters] = useState(null)

	!filters && getFilters()
		.then(response => setFilters(response.data.data))
		.catch(error => handleError(error))

	return (
		<React.Fragment>
			<Breadcrumbs title="Administrador" breadcrumbItem="Filtros Permitidos" />
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<CardTitle className="mb-3">Agregar Filtro Nuevo</CardTitle>
							<NewFilterForm reload={() => setFilters(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle className="mb-3">Listado de Filtros Permitidos</CardTitle>
							<FiltersTable filters={filters} reload={()=>setFilters(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}