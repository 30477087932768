import React, {useState} from 'react'
import { Table } from 'antd'
import { Button } from 'reactstrap'
import EditFilterModal from './EditFilterModal'

export default function FiltersTable({ filters, reload }) {
	const [editModal, setEditModal] = useState(null)

	const columns = [
		{
			title: 'Key',
			dataIndex: 'key'
		},
		{
			title: 'Name',
			dataIndex: 'name'
		},
		{
			title: 'Label',
			dataIndex: 'label'
		},
		{
			title: 'Operator',
			dataIndex: 'operator'
		},
		{
			title: 'Tipo',
			dataIndex: 'type'
		},
		{
			title: 'Acciones',
			render: (text, record) => (
				<React.Fragment>
					<Button 
						color="info" 
						outline 
						size="sm" 
						onClick={() => setEditModal(record)}
					>
						Editar
					</Button>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table
				columns={columns}
				dataSource={filters}
				rowKey={row => row.id}
				size="middle"
			/>
			<EditFilterModal
				visible={editModal ? true : false}
				onClose={reload => {
					setEditModal(null)
					if(reload) reload()
				}}
				filter={editModal}
			/>
		</React.Fragment>
	)
}