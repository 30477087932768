import React from 'react'
import {Card, CardTitle, CardBody, Spinner} from 'reactstrap'
import { Popconfirm, Empty } from 'antd'
import styled from 'styled-components'

import {detachAccountDomain} from './services'
import {handleError} from '../../helpers'
import NewDomain from './partials/NewDomain'

export default function AccountDomains({ account, reloadAccount }) {

   const handleDelete = domain => {      
      detachAccountDomain(account.data.id, domain)
         .then(() => reloadAccount())
         .catch(error => handleError(error))
   }

   return (
      <Card>
         <CardBody>
            <CardTitle>Dominios</CardTitle>
            <p className="text-muted mb-4">Administración de los dominios</p>
            { account ? (
               <React.Fragment>
                  { account.domains.length === 0 ? (
                     <Empty description="No tiene dominios" />
                  ) : account.domains.map( (domain, index) => (
                     <DomainCard key={index}>
                        <Popconfirm
                           title="¿Esta seguro de eliminar el dominio?"
                           onConfirm={()=> handleDelete(domain)}
                           okText="Eliminar"
                           cancelText="Cancelar"
                        >
                           <DomainIcon>
                              <i className="dripicons-trash"></i>
                           </DomainIcon>
                        </Popconfirm>
                        { domain }
                     </DomainCard>
                  )) }
                  <div className="text-center mt-3">
                     <NewDomain accountId={account.data.id} reloadAccount={()=>reloadAccount()} />
                  </div>
               </React.Fragment>
            ) : (
               <Spinner size="sm" />
            )}            
         </CardBody>
      </Card>
   )
}

const DomainCard = styled.div`
   background-color: #f8f8f8;
   padding: 10px 15px 5px;
   margin-bottom: 10px;
`

const DomainIcon = styled.a`
   float: right;
   & i:hover {
      color: red;
   }
`