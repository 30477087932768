import React, {useState} from 'react'
import { Row, Col } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumb';
import StatCard from '../../components/StatCard';
import AccountUsers from './AccountUsers';
import AccountDomains from './AccountDomains';
import AccountDetails from './AccountDetails';

import {getAccount} from './services'
import {handleError} from '../../helpers'

export default function AccountProfile(props) {
   const accountId = props.match.params.id
   const [account, setAccount] = useState(null)

   !account && getAccount(accountId)
      .then(response => setAccount(response.data))
      .catch(error => handleError(error))

   return (
      <React.Fragment>
         <Breadcrumbs title="Cuentas" breadcrumbItem="Pérfil de la Cuenta" />
         <Row>
            <Col lg={4}>
               <AccountDetails account={account} reloadAccount={()=>setAccount(null)} />
               <AccountDomains account={account} reloadAccount={()=>setAccount(null)} />
            </Col>
            <Col lg={8}>
               <Row>
                  <Col md="4">
                     <StatCard title="Contactos" text={account && account.stats.contacts_count} iconClass="bxs-group" />
                  </Col>
                  <Col md="4">
                     <StatCard title="Usuarios Activos" text={account && account.stats.users_count} iconClass="bxs-user" />
                  </Col>
                  <Col md="4">
                     <StatCard title="Estado Actual" text={ account && account.data.is_suspended === "0" ? 'Activa' : 'Suspendida' } iconClass="bx-power-off" />
                  </Col>
               </Row>
               <AccountUsers account={account} reloadAccount={()=>setAccount(null)} />
            </Col>
         </Row>
      </React.Fragment>
   )
}