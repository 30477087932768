import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import menuDropdown
// import NotificationDropdown from "../TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light-s4sales.png";
import logoDark from "../../assets/images/logo-dark.png";

class Header extends Component {
  constructor(props) {
    super(props);    
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLight} alt="" height="45" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="42" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button> 
            </div>

            <div className="d-flex">
              {/* <NotificationDropdown /> */}

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
