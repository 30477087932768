import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import {Form} from 'react-bootstrap'

import {attachAccountDomain} from '../services'
import { handleError } from '../../../helpers'

export default function NewDomain({ accountId, reloadAccount }) {
   const [show, setShow] = useState(false)
   const {handleSubmit, errors, register} = useForm()

   const onSubmit = values => {
      attachAccountDomain(accountId, values)
         .then(() => reloadAccount())
         .catch(error => handleError(error))
   }

   return (
      <React.Fragment>
         <Button outline color="primary" size="sm" onClick={()=>setShow(true)}>Agregar Dominio</Button>
         <Modal isOpen={show} toggle={()=>setShow(!show)} centered={true}>
            <ModalHeader toggle={()=>setShow(false)}>Agregar Nuevo Dominio</ModalHeader>
            <ModalBody>
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group>
                     <Form.Label>Ingrese el dominio nuevo</Form.Label>
                     <Form.Control 
                        name="domain"
                        placeholder="Sin utilizar http o https. Ej: demo.smart4sales.com"
                        ref={register({ 
                           required:true,
                           pattern: {
                              value: /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/,
                              message: "Dominio inválido"
                           }
                        })}
                     />
                     { errors.domain && <Form.Text className="text-warning">Ingrese un dominio válido sin protocolo.</Form.Text> }
                  </Form.Group>
                  <Button color="primary" type="submit">Agregar Dominio</Button>
               </Form>
            </ModalBody>
         </Modal>
      </React.Fragment>
   )
}