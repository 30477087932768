import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Authentication from './containers/Authentication/'
import Accounts from './containers/Accounts/'
import Filters from "./containers/Filters"
import Error404 from './containers/ErrorsPages/Error404'

import "antd/dist/antd.less";
import "./assets/scss/theme.scss"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<Switch>
			<Redirect exact path="/" to="/accounts" />

			<Route path="/auth" component={Authentication} />
			{!token && <Redirect to="/auth/login" />}
			
			<Route path="/accounts" component={Accounts} />
			<Route path="/filters" component={Filters} />

			<Route component={Error404} />
		</Switch>
	);	
}